<template>
	<div class="page">
		<div class="page-ct">
			<div class="option-box">
				<div class="line">
					<div class="title">地块名称</div>
					<div class="des">
						<Input placeholder="地块名称" class="option-input" v-model="formItem.landName" />
					</div>
				</div>
				<div class="line">
					<div class="title">地块位置</div>
					<div class="des">
						<Input placeholder="地块位置" class="option-input" v-model="formItem.dkwz" />
					</div>
				</div>
				<div class="line city-line">
					<div class="title">选择区域</div>
					<div class="des">
						<Select clearable filterable v-model="formItem.province" style="width: 100px" size="small"
							placeholder="省份" @on-change="selectProvince">
							<Option v-for="item in landProvinceList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}</Option>
						</Select>
						<Select clearable filterable v-model="formItem.city" style="width: 100px" size="small"
							placeholder="城市" @on-change="selectCity">
							<Option v-for="item in cityList" :value="item.areaId" :key="item.areaId">{{ item.name }}
							</Option>
						</Select>
						<Select clearable filterable v-model="formItem.region" style="width: 100px" size="small"
							disabled placeholder="行政区">
							<Option v-for="item in regionList" :value="item.areaId" :key="item.areaId">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>
				<div class="line">
					<div class="title">出让方式</div>
					<div class="des">
						<Checkbox :value="checkSellAll" @click.prevent.native="
                handleCheckAll(checkSellAll, sellWay, 'sellWay')
              ">全选</Checkbox>
						<CheckboxGroup v-model="formItem.sellWay">
							<Checkbox v-for="(item, index) in sellWay" :key="index" :label="item.id">
								{{ item.name }}
							</Checkbox>
						</CheckboxGroup>
					</div>
				</div>
				<div class="line">
					<div class="title">土地用途</div>
					<div class="des">
						<Checkbox :value="checkLandAll" @click.prevent.native="
                handleCheckAll(checkLandAll, filterLandUse, 'landUse')
              ">全选</Checkbox>
						<CheckboxGroup v-model="formItem.landUse">
							<Checkbox v-for="(item, index) in filterLandUse" :key="index" :label="item.id">
								{{ item.name }}</Checkbox>
						</CheckboxGroup>
					</div>
				</div>
				<div class="line city-line">
					<div class="title">筛选条件</div>
					<div class="des">
						<Select v-model="formItem.dealState" style="width: 100px" size="small" placeholder="交易状态">
							<Option v-for="item in dealState" :value="item.id" :key="item.id">{{ item.name }}</Option>
						</Select>
						<Select v-model="formItem.tmp" style="width: 120px" size="small" placeholder="保障房类型"></Select>
						<Select v-model="formItem.tmp" style="width: 100px" size="small" placeholder="出让年限"></Select>
						<Select v-model="formItem.tmp" style="width: 120px" size="small" placeholder="是否含标书"></Select>
						<div class="more-btn" @click="switchMore">更多条件</div>
					</div>
				</div>
				<div class="more" :class="{ open: open }">
					<div class="show-box" v-show="open">
						<div class="line">
							<div class="left">
								<div class="title">公告时间</div>
								<div class="des">
									<DatePicker v-model="formItem.announcementTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'announcementTime', 0)"
										style="width: 96px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.announcementTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'announcementTime', 1)"
										style="width: 96px"></DatePicker>
								</div>
							</div>
							<div class="right">
								<div class="title">土地编号</div>
								<div class="des">
									<Input v-model="formItem.landCode" placeholder="土地编号" class="option-input" />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">溢价率</div>
								<div class="des">
									<Input type="number" v-model="formItem.premiumRateStart" placeholder="溢价率"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'premiumRate', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.premiumRateEnd" placeholder="溢价率"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'premiumRate', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">起始时间</div>
								<div class="des">
									<DatePicker v-model="formItem.startingTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'startingTime', 0)"
										style="width: 96px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.startingTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'startingTime', 1)"
										style="width: 96px"></DatePicker>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">容积率</div>
								<div class="des">
									<Input type="number" v-model="formItem.plotRatioStart" placeholder="容积率"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'plotRatio', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.plotRatioEnd" placeholder="容积率"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'plotRatio', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">成交时间</div>
								<div class="des">
									<DatePicker v-model="formItem.closingTimeStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'closingTime', 0)"
										style="width: 96px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.closingTimeEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'closingTime', 1)"
										style="width: 96px"></DatePicker>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">受让单位</div>
								<div class="des">
									<Input v-model="formItem.effectivePrescription" placeholder="受让单位"
										class="option-input" />
								</div>
							</div>
							<div class="right">
								<div class="title">截止时间</div>
								<div class="des">
									<DatePicker v-model="formItem.deadlineStart" type="date" placeholder="开始时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'deadline', 0)"
										style="width: 96px"></DatePicker>
									<span class="split">-</span>
									<DatePicker v-model="formItem.deadlineEnd" type="date" placeholder="结束时间"
										format="yyyy-MM-dd" @on-change="changeRangeVal($event, 'deadline', 1)"
										style="width: 96px"></DatePicker>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">起始价(万元)</div>
								<div class="des">
									<Input type="number" v-model="formItem.startPriceStart" placeholder="起始价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'startPrice', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.startPriceEnd" placeholder="起始价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'startPrice', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">成交价(万元)</div>
								<div class="des">
									<Input type="number" v-model="formItem.transactionTotalPriceStart" placeholder="成交价"
										class="option-input sm" @on-change="
                      changeRangeVal(
                        $event.target.value,
                        'transactionTotalPrice',
                        0
                      )
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.transactionTotalPriceEnd" placeholder="成交价"
										class="option-input sm" @on-change="
                      changeRangeVal(
                        $event.target.value,
                        'transactionTotalPrice',
                        1
                      )
                    " />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">推出单价</div>
								<div class="des">
									<Input type="number" v-model="formItem.unitPriceStart" placeholder="推出单价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'unitPrice', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.unitPriceEnd" placeholder="推出单价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'unitPrice', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">成交单价</div>
								<div class="des">
									<Input type="number" v-model="formItem.transactionPriceStart" placeholder="成交单价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'transactionPrice', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.transactionPriceEnd" placeholder="成交单价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'transactionPrice', 1)
                    " />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">推出楼面价</div>
								<div class="des">
									<Input type="number" v-model="formItem.launchFloorPriceStart" placeholder="推出楼面价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'launchFloorPrice', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.launchFloorPriceEnd" placeholder="推出楼面价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'launchFloorPrice', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">成交楼面价</div>
								<div class="des">
									<Input type="number" v-model="formItem.floorPriceStart" placeholder="成交楼面价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'floorPrice', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.floorPriceEnd" placeholder="成交楼面价"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'floorPrice', 1)
                    " />
								</div>
							</div>
						</div>
						<div class="line">
							<div class="left">
								<div class="title">用地面积</div>
								<div class="des">
									<Input type="number" v-model="formItem.landAreaStart" placeholder="用地面积"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'landArea', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.landAreaEnd" placeholder="用地面积"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'landArea', 1)
                    " />
								</div>
							</div>
							<div class="right">
								<div class="title">建筑面积</div>
								<div class="des">
									<Input type="number" v-model="formItem.buildAreaStart" placeholder="建筑面积"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'buildArea', 0)
                    " />
									<span class="split">-</span>
									<Input type="number" v-model="formItem.buildAreaEnd" placeholder="建筑面积"
										class="option-input sm" @on-change="
                      changeRangeVal($event.target.value, 'buildArea', 1)
                    " />
								</div>
							</div>
						</div>
					</div>

					<div class="btn-line">
						<div class="btn check searchBtnLog" data-id="1" @click="search">查询</div>
						<div class="btn cancel">取消</div>
					</div>
					<div class="switch" @click="switchMore">
						<Icon type="ios-arrow-up" />
					</div>
				</div>
			</div>

			<div class="action-line">
				<DownloadBtn :id="1007" @exportData="exportData" :style="{
            width: '72px',
            height: '28px',
            position: 'relative',
            'margin-right': '8px',
            left: '0px',
            'font-size': '14px',
            'background-color': 'rgba(241, 110, 113, 1)',
          }" :type=1></DownloadBtn>
				<!-- <div class="btn index">指标选择</div> -->
				<div class="btn save" @click="saveTemplate">保存模板</div>
				<div class="btn my" @click="showModalManage = true">我的模板</div>
			</div>

			<div class="choose-box">
				<TagGroup :tagList="selectList" @close="delTagList">
					<Tag type="border" v-if="formItem.province || formItem.city || formItem.region"
						@on-close="delSingle('city')" >
						区域:
						<span class="tabContent">
							{{ formatProvince(formItem.province) }}
							{{ formatCity(formItem.city) }}
							{{ formatRegion(formItem.region) }}
						</span>
					</Tag>
					<template slot="end">
						<Tag type="border" v-for="(item, key) in chooseFormItemOption" v-show="formItem[key]" :key="key"
							@on-close="delSingle(key, item)" >
							{{ item }}:
							<span class="tabContent">{{ formItem[key] }}</span>
						</Tag>
						<Tag type="border" v-for="(item, key) in chooseTimeOption"
							v-show="formItem[`${key}Start`] && formItem[`${key}End`]" :key="key"
							@on-close="delSingle(key, item)" >
							{{ item }}:
							<span class="tabContent">{{ formItem[key].join("-") }}</span>
						</Tag>
						<Tag type="border" v-for="(item, key) in chooseRangeOption"
							v-show="formItem[`${key}Start`] && formItem[`${key}End`]" :key="key"
							@on-close="delSingle(key, item)" >
							{{ item }}:
							<span class="tabContent">{{ formItem[key].join("-") }}</span>
						</Tag>
					</template>
				</TagGroup>
			</div>

			<div class="table-box">
				<Table :columns="tableColumns" :data="tableData" border height="550" tooltip-theme="light" class="table"
					@on-cell-click="tableCellClick" :loading="loading"></Table>
				<Page :total="total" :current="formItem.current" show-elevator show-sizer
					@on-page-size-change="pageSizeChange" @on-change="pageChange" />
			</div>
		</div>
		<modalManage :saveType="2" @chooseTemplate="chooseTemplate"></modalManage>

		<addProjectModal :id="addProjectId" type="place" :address="address" :selfProjectName='projectName'></addProjectModal>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import mixin from "@/mixins";
	import formMixin from "@/mixins/form";
	import addProjectModal from "@/components/modal/addProject";
	import modalManage from "@/components/modal/modalManage";
	import tableBtn from "@/components/insight/TableBtn";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		conditionSearch,
		getLandSingleSelectCity,
		getLandSingleSelectRegion,
	} from "@/api/public";
	import {
		downloadData,
		formatJson
	} from "@/utils";
	import {
		followPlace
	} from "@/api/placeMarket";
	import dataReports from "@/mixins/dataReports";

	export default {
		name: "placeOptions",
		components: {
			addProjectModal,
			modalManage,
			DownloadBtn,
			// HorizenTable
		},
		mixins: [mixin, formMixin, dataReports],
		data() {
			return {
				projectName:'',
				open: false,
				showDetail: false,
				cityList: [],
				regionList: [],
				formItem: {
					dkwz: "",
					province: "",
					city: "",
					region: "",
					dealState: "",
					size: 10,
					current: 1,
					// 公告时间
					announcementTime: [],
					announcementTimeStart: "",
					announcementTimeEnd: "",
					// 建筑面积
					buildArea: [],
					buildAreaStart: "",
					buildAreaEnd: "",
					// 成交时间
					closingTime: [],
					closingTimeStart: "",
					closingTimeEnd: "",
					// 截至时间
					deadline: [],
					deadlineStart: "",
					deadlineEnd: "",
					detailedPlanning: "",
					effectivePrescription: "",
					landCode: "",
					landName: "",
					landUse: [],
					// 容积率
					plotRatio: [],
					plotRatioStart: "",
					plotRatioEnd: "",
					// 溢价率
					premiumRate: [],
					premiumRateStart: "",
					premiumRateEnd: "",
					// 起始价
					startPrice: [],
					startPriceStart: "",
					startPriceEnd: "",
					// 成交价
					transactionTotalPrice: [],
					transactionTotalPriceStart: "",
					transactionTotalPriceEnd: "",
					// 推出单价
					unitPrice: [],
					unitPriceStart: "",
					unitPriceEnd: "",
					// 成交单价
					transactionPrice: [],
					transactionPriceStart: "",
					transactionPriceEnd: "",
					// 推出楼面价
					launchFloorPrice: [],
					launchFloorPriceStart: "",
					launchFloorPriceEnd: "",
					// 成交楼面价
					floorPrice: [],
					floorPriceStart: "",
					floorPriceEnd: "",
					// 用地面积
					landArea: [],
					landAreaStart: "",
					landAreaEnd: "",

					sellWay: [],
					startingPrice: [],
					// 起始时间
					startingTime: [],
					startingTimeStart: "",
					startingTimeEnd: "",
					transactionStatus: [],
					transferAge: "",
					typeOfSecurityRoom: "",
				},
				chooseFormItemOption: {
					landName: "地块名称",
					effectivePrescription: "受让单位",
					landCode: "土地编号",
					detailedPlanning: "详细规划",
					dkwz: "地块位置",
				},
				chooseTimeOption: {
					announcementTime: "公告时间",
					closingTime: "成交时间",
					deadline: "截止时间",
					startingTime: "起始时间",
				},
				chooseRangeOption: {
					plotRatio: "容积率",
					premiumRate: "溢价率",
					startPrice: "起始价",
					transactionTotalPrice: "成交价",
					unitPrice: "推出单价",
					transactionPrice: "成交单价",
					launchFloorPrice: "推出楼面价",
					floorPrice: "成交楼面价",
					landArea: "用地面积",
					buildArea: "建筑面积",
				},

				tableData: [],
				total: 0,
				loading: false,
				land: {},

				address: {},
				addProjectId: -1,
			};
		},
		computed: {
			...mapState(["landUse", "sellWay", "dealState", "landProvinceList"]),
			checkSellAll() {
				return this.formItem.sellWay.length == this.sellWay.length;
			},
			checkLandAll() {
				return this.formItem.landUse.length == this.filterLandUse.length;
			},
			filterLandUse() {
				let arr = [];
				let list = JSON.parse(JSON.stringify(this.landUse));
				list = list.slice(0, 4);
				// if(this.tabsIndex == 1) {
				// 	list.splice(list.length-1,1);
				// }
				if (list) arr = list;
				return arr;
			},
			// 用来显示筛选文字用
			selectionOption() {
				return [{
						name: "交易状态",
						key: "dealState",
						type: "option",
					},
					{
						name: "土地用途",
						key: "landUse",
						type: "list",
					},
					{
						name: "出让方式",
						key: "sellWay",
						type: "list",
					},
				];
			},
			optionList() {
				return {
					landUse: this.landUse,
					sellWay: this.sellWay,
					dealState: this.dealState,
				};
			},
			tableColumns() {
				let arr = [];
				arr = [{
						title: "",
						key: "pinggu",
						align: "center",
						width: 160,
						render: (h, params) => {
							return h(tableBtn, {
								props: {
									item: params.row,
								},
								on: {
									click: (params) => {
										this.handleAddStar(params);
									},
									txtClick: (params) => {
										this.handleAddProject(params);
									},
								},
								// on: {
								//     click: () => {
								//         this.address.lng = params.row.jd;
								//         this.address.lat = params.row.wd;
								//         this.showAddProjectModal = true;
								//         this.addProjectId = params.row.id;
								//     }
								// },
							});
						},
						renderHeader: (h, index) => {
							return h("div", "");
						},
					},
					{
						title: "交易状态",
						key: "statetxt",
						align: "center",
						width: 100,
						tooltip: true,
					},
					{
						title: "地块名称",
						key: "zdbhCr",
						align: "center",
						width: 140,
						tooltip: true,
					},
					{
						title: "地块位置",
						key: "zdzl",
						align: "center",
						width: 140,
						tooltip: true,
					},
					{
						title: "城市",
						key: "cs",
						align: "center",
						minWidth: 100,
					},
					{
						title: "土地用途",
						key: "tdyt",
						align: "center",
						minWidth: 100,
					},
					{
						title: "土地面积",
						key: "zydmj",
						align: "center",
						minWidth: 130,
						renderHeader: (h, index) => {
							return h("div", [h("div", "土地面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.zydmj == "null" || params.row.zydmj == "" ?
								"" :
								parseFloat(params.row.zydmj).toFixed(2)
							);
						},
					},
					{
						title: "规划建筑面积",
						key: "ghjzmj",
						align: "center",
						minWidth: 130,
						renderHeader: (h, index) => {
							return h("div", [h("div", "规划建筑面积"), h("div", "（㎡）")]);
						},
						render: (h, params) => {
							return h(
								"span",
								params.row.ghjzmj == "null" || params.row.ghjzmj == "" ?
								"" :
								parseFloat(params.row.ghjzmj).toFixed(2)
							);
						},
					},
					{
						title: "容积率",
						key: "rjlms",
						align: "center",
						minWidth: 100,
						tooltip: true,
					},
					{
						title: "出让方式",
						key: "crfsCr",
						align: "center",
						minWidth: 100,
					},
					{
						title: "截止日期",
						key: "zpgjzsj",
						align: "center",
						minWidth: 120,
						render: (h, params) => {
							return h("span", params.row.zpgjzsj);
						},
					},
					{
						title: "起始价（万元）",
						key: "qsjms",
						align: "center",
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "起始价"), h("div", "（万元）")]);
						},
						minWidth: 120,
						render: (h, params) => {
							return h("span", params.row.qsjms.replace("万元", ""));
						},
					},
				];
				if (this.formItem.dealState != 2 && this.formItem.dealState != 3) {
					arr.push({
						title: "成交价",
						key: "cjjms",
						align: "center",
						width: 140,
						tooltip: true,
						renderHeader: (h, index) => {
							return h("div", [h("div", "成交价"), h("div", "（万元）")]);
						},
					}, {
						title: "成交楼面价",
						key: "cjlmj",
						align: "center",
						width: 140,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "成交楼面价"), h("div", "（元/㎡）")]);
						},
					}, {
						title: "溢价率",
						key: "yjl",
						align: "center",
						render: (h, params) => {
							return h("span", params.row.yjl || "0.00%");
						},
						minWidth: 100,
					}, {
						title: "受让单位",
						key: "sldw",
						align: "center",
						width: 140,
						tooltip: true,
					});
				}
				return arr;
			},

			showModalManage: {
				get() {
					return this.$store.state.modal.showModalManage;
				},
				set(newValue) {
					this.$store.state.modal.showModalManage = newValue;
				},
			},
			saveSearchData: {
				get() {
					return this.$store.state.saveSearchData;
				},
				set(newValue) {
					this.$store.state.saveSearchData = newValue;
				},
			},
			showAddProjectModal: {
				get() {
					return this.$store.state.modal.showAddProjectModal;
				},
				set(value) {
					this.$store.state.modal.showAddProjectModal = value;
				},
			},
		},
		beforeMount() {
			this.$Notice.destroy();
		},
		mounted() {
			this.getParams();
			this.info1();
		},
		methods: {
			info1() {

			},
			formatSellWay(id) {
				let str = "";
				this.sellWay.map((item) => {
					if (item.id == id) {
						str = item.name;
					}
				});
				return str;
			},
			// 参数获取列表
			async getParams() {
				let item = this.formItem;
				let query = this.$route.query;
				if (Object.keys(query).length) {
					if (query.province) {
						item.province = +query.province;
						await this.selectProvince(item.province);
						query.city && (item.city = +query.city);
						await this.selectCity(item.city);
						query.region && (item.region = +query.region);
					}
					// item.city = query.city;
					item.landName = query.searchVal;
					item.dkwz = query.searchVal2;
					query.sellWay && (item.sellWay = [+query.sellWay]);
					query.dealState && (item.dealState = +query.dealState);
					if (query.landUse) {
						let arr = JSON.parse(query.landUse);
						arr.remove(0);
						item.landUse = arr;
					}
					this.getList();
				} else {
					item.landUse = [2, 3];
					item.province = 20;
					await this.selectProvince(item.province);
					item.city = 235;
					await this.selectCity(item.city);
					this.getList();
				}
			},
			exportData() {
				const params = this.getSearchData();
				params.size = 100000000;
				params.current = 1;
				conditionSearch(params).then((data) => {
					const tableData = data.records;
					let filterVal = [];
					let tHeader = [];
					this.tableColumns.forEach((item) => {
						tHeader.push(item.title);
						filterVal.push(item.key);
					});
					const resData = formatJson(filterVal, tableData);
					downloadData(tHeader, resData, "条件搜索");
				});
			},
			changeTabs(index) {
				if (this.tabsIndex == index) return;
				this.tabsIndex = index;
			},
			switchMore() {
				this.open = !this.open;
			},
			async selectProvince(item) {
				return new Promise((resolve) => {
					getLandSingleSelectCity({
						id: item,
					}).then((data) => {
						this.cityList = data;
						this.formItem.city = "";
						this.regionList = [];
						this.formItem.region = "";
						resolve();
					});
				});
			},
			async selectCity(item) {
				return new Promise((resolve) => {
					getLandSingleSelectRegion({
						id: item,
					}).then((data) => {
						this.regionList = data;
						this.formItem.region = "";
						resolve();
					});
				});
			},

			// 全选checkbox用
			handleCheckAll(val, list, key) {
				let item = this.formItem;
				// 已经全选
				if (val) {
					item[key] = [];
				} else {
					item[key] = list.map((item) => {
						return item.id;
					});
				}
			},
			// 已选提交的操作
			delSingle(type, val, index) {
				let item = this.formItem;
				if (type == "landUse" || type == "sellWay") {
					item[type].splice(index, 1);
				} else if (type == "city") {
					item.province = "";
					item.city = "";
					item.region = "";
				} else if (this.chooseFormItemOption[type]) {
					item[type] = "";
				} else if (this.chooseTimeOption[type] || this.chooseRangeOption[type]) {
					item[`${type}Start`] = "";
					item[`${type}End`] = "";
					item[type] = [];
				} else {
					item[type] = "";
					// this.nowFormItem.province = '';
					// this.nowFormItem.city = '';
					// this.nowFormItem.region = '';
				}
			},
			delTagList(obj) {
				let item = this.nowFormItem || this.formItem;
				let key = obj.key;
				// let option = this.selectionOption[obj.key];
				if (obj.type == "string" || obj.type == "option") {
					item[key] = "";
				} else if (obj.type == "range") {
					item[`${key}Start`] = "";
					item[`${key}End`] = "";
					item[key] = [];
				} else if (obj.type == "list") {
					item[key] = [];
				}
			},

			changeRangeVal(val, type, index) {
				this.$set(this.formItem[type], index, val);
			},
			getSearchData() {
				let item = Object.assign({}, this.formItem);

				if (item.dealState) {
					item.transactionStatus = [+item.dealState];
				}

				// 时间范围
				if (!item.announcementTimeStart || !item.announcementTimeEnd) {
					delete item.announcementTime;
				}
				if (!item.closingTimeStart || !item.closingTimeEnd) {
					delete item.closingTime;
				}
				if (!item.deadlineStart || !item.deadlineEnd) {
					delete item.deadline;
				}
				if (!item.startingTimeStart || !item.startingTimeEnd) {
					delete item.startingTime;
				}

				// 数值范围
				if (!item.plotRatioStart || !item.plotRatioEnd) {
					delete item.plotRatio;
				}
				if (!item.premiumRateStart || !item.premiumRateEnd) {
					delete item.premiumRate;
				}
				return item;
			},
			saveTemplate() {
				this.saveSearchData = JSON.stringify(this.getSearchData());
				this.showModalManage = true;
			},
			chooseTemplate(item) {
				let formItem = this.formItem;
				let params = JSON.parse(item.templateParams);
				// formItem = params;
				for (let key in params) {
					formItem[key] = params[key];
				}
				formItem.current = 1;
				this.getList();
			},
			search() {
				this.formItem.current = 1;
				this.getList();
			},
			getList() {
				this.open = false;
				for (let key in this.chooseRangeOption) {
					// 传过值
					if (this.formItem[key].length) {
						let start = this.formItem[`${key}Start`];
						let end = this.formItem[`${key}End`];
						// 都是空值 继续
						if (!start && !end) continue;
						// 只有单一值 返回
						if (!start || !end) {
							// this.$Message.warning(
							//   `${this.chooseRangeOption[key]}，请选择一个范围`
							// );
							this.$msg.error({
								text: `${this.chooseRangeOption[key]}，请选择一个范围`,
							});

							return;
						}
					}
				}
				this.loading = true;
				this.tableData = [];
				conditionSearch(this.getSearchData())
					.then((data) => {
						this.total = data.total;
						this.tableData = data.records.map((item) => {
							if (item.zdbhCr == "NULL") {
								item.zdbhCr = "";
							}
							item.cjlmj && (item.cjlmj = Number((+item.cjlmj).toFixed(0)));
							item.statetxt = this.formalName("dealState", item.state);
							return item;
						});
						this.land = this.tableData[0];
					})
					.finally(() => {
						this.loading = false;
					});
			},
			pageSizeChange(size) {
				this.formItem.current = 1;
				this.formItem.size = size;
				this.getList();
			},
			pageChange(size) {
				this.formItem.current = size;
				this.getList();
			},

			tableCellClick(row, column, data, event) {
				if (column.key == "zdbhCr") {
					let routeData = this.$router.resolve({
						path: `/Insight/PlaceDetail`,
						query: {
							id: row.id,
							type: "option",
						},
					});
					window.open(routeData.href, "_blank");
				}
			},

			handleAddStar(item) {
				followPlace({
					state: item.star ? -1 : 1,
					type: 1,
					unionId: item.id,
				}).then((data) => {
					if (item.star) {
						// this.$msg.success("取消关注成功");
						this.$msg.error({
							type: "remind",
							code: 4035
						});

						item.star = false;
					} else {
						// this.$msg.success("关注成功");
						this.$msg.error({
							type: "remind",
							code: 4036
						});

						item.star = true;
					}
				});
			},
			handleAddProject(data) {
				this.showAddProjectModal = true;
				this.addProjectId = data.id;
				this.address.lng = data.jd;
				this.address.lat = data.wd;
				this.projectName = data.ppbm
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.page {
		width: 100%;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding-top: 20px;
		background: #fff;
		overflow: hidden;
		color: #000;

		.page-ct {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-width: 1200px;
			padding: 0 20px;
		}

		.option-box {
			width: 100%;
			margin-bottom: 10px;
			background-color: #fff;
			border: 1px solid #f1f1f1;

			.line {
				@include flex(flex-start);
				height: 48px;

				.title {
					@include flex;
					width: 140px;
					height: 100%;
					background-color: rgba(#f8f8f8, 0.47);
				}

				.des {
					position: relative;
					@include flex(flex-start);
					flex-grow: 1;
					height: 100%;
					padding-left: 40px;
					border-bottom: 1px solid rgba(#ccc, 0.48);

					.split {
						margin: 0 8px;
						color: #cfcfcf;
					}

					.option-input {
						width: 200px;
						height: 30px;
						border: 1px solid rgba(228, 228, 228, 1);
						border-radius: 5px;

						&.sm {
							width: 86px;
						}
					}

					.more-btn {
						margin-left: auto;
						margin-right: 10px;
					}
				}

				.left,
				.right {
					@include flex(flex-start);
					flex: 1;
					height: 100%;
				}

				.right {
					border-left: 1px solid #f1f1f1;
					border-bottom: 1px solid #f1f1f1;
				}

				&.city-line {
					.ivu-select {
						margin-right: 10px;
					}
				}
			}

			.more {
				position: relative;
				border: 2px solid transparent;

				.title {
					color: #666;
					background-color: #fffdef;
				}

				.des {
					border-left: 1px solid #f1f1f1;
				}

				&.open {
					border-color: #edd189;

					.switch {
						border-color: #edd189;

						/deep/ .ivu-icon {
							transform: rotate(0);
						}
					}
				}
			}

			.btn-line {
				@include flex;
				margin: 15px auto;

				.btn {
					@include flex;
					width: 60px;
					height: 30px;
					margin-right: 15px;
					background-color: #f7f7f7;
					border: 1px solid #ececec;
					cursor: pointer;

					&.disable {
						color: #eee;
						background-color: #fff;
						cursor: auto;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.switch {
				@include flex;
				position: absolute;
				width: 104px;
				height: 20px;
				left: 50%;
				bottom: -20px;
				color: #b7b7b7;
				background-color: #fff;
				border: 2px solid transparent;
				border-top: none;
				transition: transform 0.3s;
				transform: translate(-50%, 0);
				cursor: pointer;

				/deep/ .ivu-icon {
					transform: rotate(180deg);
					transition: transform 0.3s;
				}
			}

			input {
				padding-left: 10px;
			}
		}

		.action-line {
			@include flex(flex-end);
			width: 100%;
			margin: 10px 0;

			.btn {
				@include flex;
				width: 72px;
				height: 28px;
				margin-right: 8px;
				color: #fff;
				background: rgba(53, 183, 224, 1);
				border-radius: 5px;
				cursor: pointer;

				&.save {
					background-color: #30ce77;
				}

				&.my {
					background-color: #6595fc;
				}
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			min-height: 40px;
			padding: 0 5px;
			background-color: #f0f0f0;

			.title {
				flex-shrink: 0;
				color: #666;
			}

			.des {
				// @include flex(flex-start,flex-start);
			}
		}

		.table-box {
			width: 100%;

			.ivu-page {
				position: relative;
				margin: 15px 0;
				text-align: right;
			}
		}
	}
</style>
